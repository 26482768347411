export enum PumpType {
  BoomPump = 1,
  LinePumpTrailer = 2,
  LinePumpTruckMounted = 3,
  PlacingBoom = 4,
}

export enum Step {
  PumpType = "/pump-type",
  PumpModel = "/pump-model",
  Truck = "/truck",
  Options = "/options",
  Summary = "/summary",
}

export enum Country {
  USA = "US",
  CANADA = "CA",
}
// export enum Step {
//   PumpType = "/pump-type",
//   PumpModel = "/pump-model",
//   Truck = "/truck",
//   Options = "/options",
//   Summary = "/summary",
// }
