import {Button, Col, Collapse, Form, Input, Radio, Row, Select} from 'antd';
import {Country} from "../types";
import {useState} from "react";
import "./Summary.scss";
import {SummaryProps} from "./types";
import {CA_STATE_MAP, US_STATE_MAP} from "./constants";
import {ImageSlider} from "../../components/ImageSlider";

const {Option} = Select;
const {Panel} = Collapse;

export const Summary = ({onSubmit, pumpModel, truckModel, hasTruck}: SummaryProps) => {
  const [country, setCountry] = useState<Country>(Country.USA);
  const [form] = Form.useForm();

  return (
    <div className={"summary-wrapper"}>
      <div className={"summary-left-section"}>
        <ImageSlider images={["/image/pump_type/temp1.png"]} label={""}
                     subLabel={""}/>
        <div className={"summary-summary"}>
          <div className={"summary-summary-pump"}>
            {`Pump Model: ${pumpModel?.title}`}
          </div>
          {hasTruck && <div className={"summary-summary-truck"}>
            {`Truck Model: ${truckModel?.truckModelName}`}
          </div>}
        </div>
        <Collapse className={"summary-collapse-header"} expandIconPosition={"right"}>
          {/*<Panel header="PUMP" key="1">*/}
          {/*  <div className={"summary-panel-content"}>*/}
          {/*    {`PumpModel: ${pumpModel?.title}`}*/}
          {/*  </div>*/}
          {/*</Panel>*/}
          {/*<Panel header="TRUCK" key="2">*/}
          {/*  <div className={"summary-panel-content"}>*/}
          {/*    {`TruckModel: ${truckModel?.truckModelName}`}*/}
          {/*  </div>*/}
          {/*</Panel>*/}
          <Panel header="OPTIONS" key="3">
            <div className={"summary-panel-content"}>
              <p>Options are here</p>
              {/*<p>Option2</p>*/}
            </div>
          </Panel>
        </Collapse>
        <div>&nbsp;</div>

      </div>
      <div className={"summary-right-section"}>
        <Form
          className={"summary-form"}
          onFinish={onSubmit}
          form={form}
          layout="vertical"
        >
          <div className={"summary-form-title"}>
            REQUEST A QUOTE
          </div>
          <div className={"summary-form-description"}>
            Fill out the required fields below and get a quote of your custom build from KCP.
          </div>
          <Row>
            <Col span={11}>
              <Form.Item name={'name'} label="Name" rules={[{required: true}]}>
                <Input placeholder={"NAME"}/>
              </Form.Item>
            </Col>
            <Col span={1}/>
            <Col span={12}>
              <Form.Item name={'company_name'} label="Company Name">
                <Input placeholder={"COMPANY NAME"}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={'phone'}
                label="Phone Number"
                rules={[{required: true}]}
              >
                <Input placeholder={"PHONE NUMBER"}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={'email'}
                label="Email"
                rules={[{type: 'email', required: true}]}
              >
                <Input placeholder={"EMAIL"}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'country'} label="Country" initialValue={country} rules={[{required: true}]}>
                <Radio.Group className={"summary-country"} name="radiogroup" value={country} onChange={(e) => {
                  setCountry(e.target.value)
                  form.setFieldsValue({state: undefined});
                }}>
                  <Radio.Button value={Country.USA}>USA</Radio.Button>
                  <Radio.Button value={Country.CANADA}>CANADA</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'state'} label="State" rules={[{required: true}]}>
                <Select placeholder={"SELECT STATE"}>
                  {(country === Country.USA) ? Object.keys(US_STATE_MAP).map((stateName) =>
                    <Option key={stateName} value={US_STATE_MAP[stateName]}>
                      {stateName}
                    </Option>
                  ) : Object.keys(CA_STATE_MAP).map((stateName) =>
                    <Option key={stateName} value={CA_STATE_MAP[stateName]}>
                      {stateName}
                    </Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={'comment'}
                label="Question and Comment"
              >
                <Input.TextArea rows={6}/>
              </Form.Item>
            </Col>
          </Row>
          <div className={"summary-next-button-wrapper"}>
            <Button htmlType="submit" className={"summary-next-button"} onClick={() => {
            }
            }>{"Request"}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}
