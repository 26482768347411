import {useEffect, useState} from "react";
import {getPumpModelList} from "../../services/PumpBuilder";
import {useNavigate} from "react-router-dom";
import {Button, Image, Radio} from 'antd';
import {AiFillFilePdf, AiOutlineArrowRight} from "react-icons/ai";
import {PumpModel, PumpModelCardProps, PumpModelData, PumpModelSelectorProps} from "./types";
import "./PumpModelSelector.scss";
import {ImageSlider} from "../../components/ImageSlider";

const PumpModelCard = ({
                         selectedPumpModelId,
                         pumpModelId,
                         title,
                         alt,
                         pumpModelName,
                         imagePath,
                         onSelect,
                       }: PumpModelCardProps) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className={"pump-model-card"}>
      <Radio className={"pump-model-card-radio"} value={pumpModelId} checked={pumpModelId === selectedPumpModelId}
             onClick={onSelect}/>
      <div className={"pump-model-card-text"} onClick={onSelect}>
        <div className={"pump-model-card-title"}>{title}</div>
        <div className={"pump-model-card-subtitle"}>{pumpModelName}</div>
      </div>
      <Button className={"pump-model-card-image-view-button"} type={"text"} icon={<AiFillFilePdf/>}
              onClick={() => setVisible(true)}/>
      <Image
        width={0}
        style={{display: 'none'}}
        src="/image/pump_model/test1.jpeg"
        preview={{
          visible,
          src: '/image/pump_model/test1.jpeg',
          onVisibleChange: value => {
            setVisible(value);
          },
        }}
      />
    </div>
  )
}

export const PumpModelSelector = ({pumpType, pumpModel, setPumpModel, onSelect, hasTruck}: PumpModelSelectorProps) => {
  const [pumpModelList, setPumpModelList] = useState<PumpModel[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (pumpType === undefined) {
      return;
    }
    getPumpModelList({pump_type_id: pumpType}).then((res) => {
      const newPumpModelList = (res?.data || []).map((pm: PumpModelData, index: number) => {
        return {
          pumpModelId: pm?.pump_model_id,
          pumpModelName: pm?.pump_model_name,
          title: pm?.pump_model_display_title,
          imagePath: "/image/pump_type/type1.jpg"
        }
      });
      setPumpModelList(newPumpModelList);
      if (pumpModel === undefined && newPumpModelList.length > 0) {
        setPumpModel(newPumpModelList[0]);
      }
    })
  }, [pumpType]);

  const dummyImages = [
    "/image/origin/38zx.jpg",
    "/image/pump_type/temp2.png",
    "/image/pump_type/temp3.png",
  ]

  return (
    <div className={"pump-model-selector-wrapper"}>
      <div className={"pump-model-left-section"}>
        <ImageSlider images={dummyImages}
                     label={pumpModel?.title || ""}
                     subLabel={pumpModel?.pumpModelName || ""}/>
      </div>
      <div className={"pump-model-right-section"}>
        {pumpModelList.map((pm: PumpModel) =>
          <PumpModelCard key={pm.pumpModelId} selectedPumpModelId={pumpModel?.pumpModelId} pumpModelId={pm.pumpModelId} alt={pm.title}
                         pumpModelName={pm.pumpModelName}
                         title={pm.title} imagePath={pm.imagePath} onSelect={()=>{
                           setPumpModel(pm)
                         }} hasTruck={hasTruck}/>
        )}
        <Button className={"pump-model-next-button"} onClick={() => {
          onSelect(pumpModel);
          navigate(hasTruck ? "/truck" : "/options");
        }}>{hasTruck ? "Truck" : "Options"}&nbsp;&nbsp;<AiOutlineArrowRight fontSize={16}/></Button>
      </div>
    </div>
  )
}
