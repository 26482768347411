import {Button, Radio} from 'antd';
import {useEffect, useState} from "react";
import {getTruckList} from "../../services/PumpBuilder";
import {useNavigate} from "react-router-dom";
import "./TruckSelector.scss";
import {TruckCardProps, TruckData, TruckGroup, TruckModel, TruckSelectorProps} from "./types";
import {ImageSlider} from "../../components/ImageSlider";
import {AiOutlineArrowRight} from "react-icons/ai";

const TruckCard = ({
                     selectedTruckModelId,
                     truckModelId,
                     onSelect,
                     truckModelName,
                     truckDescription,
                     truckLink
                   }: TruckCardProps) => {
  return (
    <div className={"truck-list-card"} onClick={onSelect}>
      <Radio className={"truck-card-radio"} value={truckModelId} checked={truckModelId === selectedTruckModelId}
             onClick={onSelect}
      />
      <div className={"truck-card-text"} onClick={onSelect}>
        <div className={"truck-card-title"}>{truckModelName}</div>
        <div className={"truck-card-subtitle"}>{truckDescription}</div>
      </div>
      {<a href={truckLink} target="_blank" rel="noopener">Link</a>}
    </div>
  )
}

export const TruckSelector = ({pumpModel, truckModel, onSelect}: TruckSelectorProps) => {
  const [truckList, setTruckList] = useState<TruckGroup[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (pumpModel === undefined) {
      return;
    }
    getTruckList({pump_model_id: pumpModel?.pumpModelId}).then((res) => {
      let newTruckList: TruckGroup[] = [];
      (res?.data || []).forEach((tr: TruckData) => {
        let matchedIndex = newTruckList.findIndex((nt: TruckGroup) => nt.brand === tr.truck_brand_name);
        if (matchedIndex !== -1) {
          newTruckList[matchedIndex].truckList.push({
            truckModelId: tr.truck_model_id,
            href: 'https://www.macktrucks.com/trucks/anthem/',
            truckModelName: tr.truck_model_name,
            description:
              'description',
            content:
              'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
          });
        } else {
          newTruckList.push({
            brand: tr.truck_brand_name, truckList: [
              {
                truckModelId: tr.truck_model_id,
                href: 'https://www.macktrucks.com/trucks/anthem/',
                truckModelName: tr.truck_model_name,
                description:
                  'description',
                content:
                  'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
              }
            ]
          });
        }
      })
      setTruckList(newTruckList);
      if (truckModel === undefined && newTruckList.length > 0) {
        onSelect(newTruckList[0].truckList[0]);
      }
    });
  }, [pumpModel]);

  const dummyImages = [
    "/image/pump_type/temp1.png",
    "/image/pump_type/temp2.png",
    "/image/pump_type/temp3.png",
  ]

  return (
    <div className={"truck-selector-wrapper"}>
      <div className={"truck-left-section"}>
        <ImageSlider images={dummyImages}
                     label={truckModel?.truckModelName || ""}
                     subLabel={truckModel?.description || ""}/>
      </div>
      <div className={"truck-right-section"}>
        {truckList.map((tl: TruckGroup) =>
          <div className={"truck-card-list"}>
            <div className={"truck-brand-header"}>{tl.brand}</div>
            {(tl.truckList || []).map((tm: TruckModel) =>
              <TruckCard selectedTruckModelId={truckModel?.truckModelId} truckModelId={tm.truckModelId}
                         onSelect={() => {
                           onSelect(tm)
                         }}
                         truckModelName={tm.truckModelName} truckDescription={tm.description}
                         truckLink={tm.href}/>
            )}
          </div>
        )}
        <Button className={"truck-next-button"} onClick={() => {
          navigate("/options");
        }
        }>Options&nbsp;&nbsp;<AiOutlineArrowRight fontSize={16}/></Button>
      </div>
    </div>
  )
}
