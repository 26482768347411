import {useState} from "react";
import "./ImageSlider.scss";

interface ImageSliderProps {
  images: string[];
  label: string;
  subLabel: string;
}

export const ImageSlider = ({images, label, subLabel}: ImageSliderProps) => {
  const [slideIndex, setSlideIndex] = useState<number>(0);

  return (
    <div className="slideshow-container">
      <div className={"slideshow-label-container"}>
        <div className={"slideshow-label"}>{label}</div>
        <div className={"slideshow-sub-label"}>{subLabel}</div>
      </div>

      {images.length > 1 ? <a className="prev" onClick={() => {
        setSlideIndex((slideIndex - 1) === -1 ? images.length - 1 : slideIndex - 1)
      }}>&#10094;</a> : <div/>}
      {images.map((imagePath, index) =>
        <>
          <img className={index === slideIndex ? "active" : ""} alt={imagePath} src={imagePath}/>
        </>
      )}
      {images.length > 1 ? <a className="next" onClick={() => {
        setSlideIndex((slideIndex + 1) === images.length ? 0 : slideIndex + 1);
      }}>&#10095;</a> : <div/>}

      {images.length > 1 && <div className={"slideshow-dots"}>
        {images.map((_, index: number) =>
          <span className={`slideshow-dot ${index === slideIndex ? "dot-active" : ""}`}
                onClick={() => setSlideIndex(index)}/>
        )}
      </div>}
    </div>
  )

}
