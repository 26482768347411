import React from 'react';
import {PumpBuilder} from "./PumpBuilder";

function App() {
  return (
    <div className="App">
      <PumpBuilder/>
    </div>
  );
}

export default App;
