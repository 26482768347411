import {PumpType} from "../types";
import "./PumpTypeSelector.scss";
import {PumpTypeCardProps, PumpTypeSelectorProps} from "./types";


const PumpTypeCard = ({pumpType, title, imagePath, onSelect}: PumpTypeCardProps) => {
  return (
    <div className={"pump-type-card"}>
      <div className={"pump-type-card-image-wrapper"}>
        <img alt={title} width={180} height={140} src={imagePath} onClick={() => onSelect(pumpType)}/>
      </div>
      <div className={"pump-type-card-text"}>{title}</div>
      <button className={"pump-type-card-button"} onClick={() => onSelect(pumpType)}>
        Build
      </button>
    </div>
  )
}

export const PumpTypeSelector = ({onSelect}: PumpTypeSelectorProps) => {
  const pumpTypeList = [
    {pumpType: PumpType.BoomPump, title: "Boom Pumps", imagePath: "/image/pump_type/temp1.png"},
    {pumpType: PumpType.LinePumpTrailer, title: "Line Pumps Trailer", imagePath: "/image/pump_type/temp2.png"},
    {
      pumpType: PumpType.LinePumpTruckMounted,
      title: "Line Pumps Truck Mounted",
      imagePath: "/image/pump_type/temp3.png"
    },
    {pumpType: PumpType.PlacingBoom, title: "Placing Booms", imagePath: "/image/pump_type/temp4.png"},
  ]
  return (
    <>
      <div className={"pump-type-upside"}>
        <span className={"pump-type-title"}>
          Build your Pump
        </span>
        <span className={"pump-type-subtitle"}>
          SELECT A PUMP TYPE
        </span>
      </div>
      <div className={"pump-type-wrapper"}>
        {pumpTypeList.map((pt) =>
          <PumpTypeCard key={pt.pumpType} pumpType={pt.pumpType} title={pt.title} imagePath={pt.imagePath}
                        onSelect={onSelect}/>
        )}
      </div>
    </>
  )
}
