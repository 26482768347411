import {Button, Checkbox, Radio} from 'antd';
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getOptionList} from "../../services/PumpBuilder";
import "./Options.scss";
import {OptionData, OptionsSelectorProps} from "./types";
import {ImageSlider} from "../../components/ImageSlider";
import {AiOutlineArrowRight} from "react-icons/ai";

const OptionCard = ({title, description, optionType, setSelectedOption}: any) => {
  const onSelect = () => {
    setSelectedOption();
  }
  return (
    <div className={"option-card"} onClick={setSelectedOption}>
      {optionType === "radio" ? <Radio className={"option-card-radio"} onChange={setSelectedOption}/> : <Checkbox className={"option-card-radio"} onChange={setSelectedOption}/>}
      {/*<Radio className={"option-radio"} value={optionId} checked={optionId === selectedPumpModel} onClick={onSelect}/>*/}
      <div className={"option-card-text"} onClick={onSelect}>
        <div className={"option-card-title"}>{title}</div>
        <div className={"option-card-subtitle"}>{description}</div>
      </div>
    </div>
  )
}

export const Options = ({setSelectedOptions, pumpModel}: OptionsSelectorProps) => {
  const [selectedOption, setSelectedOption] = useState<any>({optionId: 0, title: "Option1", description: "Description1"},);
  const [optionList, setOptionList] = useState<OptionData[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (pumpModel === undefined) {
      return
    }
    getOptionList({pump_model_id: pumpModel.pumpModelId}).then((res) => {
      setOptionList(res?.data);
    });
  }, [pumpModel]);

  const dummyCheckboxOptionData = [
    {optionId: 0, title: "Option1", description: "Description1"},
    {optionId: 1, title: "Option2", description: "Description2"},
    {optionId: 2, title: "Option3", description: "Description3"},
    {optionId: 3, title: "Option4", description: "Description4"}
  ];

  const dummyRadioOptionData = [
    {optionId: 4, title: "Option1", description: "Description1"},
    {optionId: 5, title: "Option2", description: "Description2"},
    {optionId: 6, title: "Option3", description: "Description3"},
    {optionId: 7, title: "Option4", description: "Description4"},
  ];

  const dummyImages = [
    "/image/pump_type/temp1.png",
    "/image/pump_type/temp2.png",
    "/image/pump_type/temp3.png",
  ];

  return (
    <div className={"option-selector-wrapper"}>
      <div className={"option-left-section"}>
        <ImageSlider images={dummyImages} label={selectedOption?.title || ""} subLabel={selectedOption?.description || ""}/>
      </div>
      <div className={"option-right-section"}>
        {/*{optionList.map((od) =>*/}
        <div className={"option-card-list"}>
          <div className={"option-brand-header"}>Pump Options</div>
          {(dummyCheckboxOptionData || []).map((op: any) =>
            <OptionCard key={op.optionId} title={op.title} description={op.description} optionType={"checkbox"}
                        setSelectedOption={() => {setSelectedOption(op)}}/>
          )}
        </div>
        <div className={"option-card-list"}>
          <div className={"option-brand-header"}>Paint Scheme</div>
          {(dummyRadioOptionData || []).map((op: any) =>
            <OptionCard key={op.optionId} title={op.title} description={op.description} optionType={"radio"}
                        setSelectedOption={() => setSelectedOption(op)}/>
          )}
        </div>
        <Button className={"option-next-button"} onClick={() => {
          navigate("/summary");
        }
        }>Summary&nbsp;&nbsp;<AiOutlineArrowRight fontSize={16}/></Button>
      </div>
    </div>
  )
}
