import React, {useEffect, useState} from 'react';
import './PumpBuilder.scss';
import {Layout} from 'antd';
import {PumpTypeSelector} from "./PumpTypeSelector/PumpTypeSelector";
import {PumpType, Step} from "./types";
import {useLocation, useNavigate} from "react-router-dom";
import {PumpModelSelector} from "./PumpModelSelector/PumpModelSelector";
import {TruckSelector} from "./TruckSelector/TruckSelector";
import {Summary} from "./Summary/Summary";
import {Options} from "./Options/Options";
import {ReqSetPumpBuilderRequestQuote} from "../services/types";
import {setPumpBuilderRequestQuote} from "../services/PumpBuilder";
import {PumpModel} from "./PumpModelSelector/types";
import {TruckModel} from "./TruckSelector/types";

const {Header, Content} = Layout;

export interface RequestQuoteFormValues {
  country: string;
  state: string;
  name: string;
  phone: string;
  company_name: string | undefined;
  email: string;
  comment: string | undefined
}

export const PumpBuilder = () => {
  const [pumpType, setPumpType] = useState<PumpType | undefined>(Number(localStorage.getItem("pt")));
  const [pumpModel, setPumpModel] = useState<PumpModel | undefined>();
  const [truckModel, setTruckModel] = useState<TruckModel | undefined>();
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
  const {pathname} = useLocation();
  const hasTruck = (pumpType === PumpType.BoomPump || pumpType === PumpType.LinePumpTruckMounted);
  const navigate = useNavigate();
  const stepperItems = hasTruck ?
    [
      {key: Step.PumpType, label: "TYPE"},
      {key: Step.PumpModel, label: "MODEL"},
      {key: Step.Truck, label: "TRUCK"},
      {key: Step.Options, label: "OPTIONS"},
      {key: Step.Summary, label: "SUMMARY"},
    ] :
    [
      {key: Step.PumpType, label: "TYPE"},
      {key: Step.PumpModel, label: "MODEL"},
      {key: Step.Options, label: "OPTIONS"},
      {key: Step.Summary, label: "SUMMARY"},
    ];

  if (pathname === "/") {
    localStorage.clear();
  }

  const onSubmit = (values: RequestQuoteFormValues) => {
    if (pumpModel === undefined) {
      return
    }

    let data: ReqSetPumpBuilderRequestQuote = {
      country: values.country,
      state: values.state,
      pump_model_id: pumpModel.pumpModelId,
      truck_model_id: truckModel?.truckModelId,
      option_ids: selectedOptions,

      name: values.name,
      phone: values.phone,
      company_name: values.company_name || "",
      email: values.email,
      comment: values.comment || "",
    }
    setPumpBuilderRequestQuote(data).then().catch();
  }

  const renderStep = (stepKey: string, label: string, index: number) => {
    const getStepperClassName = (stepKey: string, index: number) => {
      const checkSelected = `${(stepKey === pathname || (pathname === "/" && index === 0)) ? "selected" : ""}
            ${(index > 0 && (stepperItems[index - 1].key === pathname || (pathname === "/" && index === 1))) ? "selected2" : ""}`;
      return `stepper-item ${checkSelected} `;
    }
    const isClickable = () => {
      const hasPumpType = localStorage.getItem("pt") !== null;
      const hasPumpModel = localStorage.getItem("pm") !== null;
      const hasTruckModel = localStorage.getItem("tm") !== null;
      const hasOptions = localStorage.getItem("ops") !== null;

      switch (stepKey) {
        case Step.PumpType:
          return true;
        case Step.PumpModel:
          return pathname !== "/" && (hasPumpType || hasPumpModel);
        case Step.Truck:
          return hasTruck && (hasPumpModel || hasTruckModel);
        case Step.Options:
          return hasOptions || (hasTruck ? hasTruckModel : hasPumpModel);
        case Step.Summary:
          return hasTruck ? hasTruckModel : hasPumpModel;
        default:
          return false;
      }
    };

    return (
      <div key={index}
           className={`${getStepperClassName(stepKey, index)} ${isClickable() ? "" : "disabled"}`}
           onClick={() => {
             if (isClickable()) {
               if (pathname === "/" && stepKey === Step.PumpType) {
               } else {
                 navigate(stepKey);
               }
             }
           }}>
        <span className={"stepper-text"}>{label}</span>
      </div>
    )
  }


  const contentSelector = () => {
    const onSelect = (selected: any) => {
      switch (pathname) {
        case "/":
        case Step.PumpType:
          localStorage.clear();
          localStorage.setItem("pt", String(selected));
          setPumpType(selected);
          setPumpModel(undefined);
          setTruckModel(undefined);
          setSelectedOptions([]);
          navigate(Step.PumpModel);
          break;
        case Step.PumpModel:
          localStorage.removeItem("tm");
          localStorage.removeItem("ops");
          localStorage.setItem("pm", JSON.stringify(selected));
          setPumpModel(selected)
          setTruckModel(undefined);
          setSelectedOptions([]);
          break;
        case Step.Truck:
          localStorage.removeItem("ops");
          localStorage.setItem("tm", JSON.stringify(selected));
          setTruckModel(selected)
          setSelectedOptions([]);
          break;
        case Step.Options:
          break;
        case Step.Summary:
          break;
        default:
          break;
      }
    }

    switch (pathname) {
      case Step.PumpType:
        return <PumpTypeSelector onSelect={onSelect}/>
      case Step.PumpModel:
        return <PumpModelSelector pumpType={pumpType} pumpModel={pumpModel} setPumpModel={setPumpModel} onSelect={onSelect}
                                  hasTruck={hasTruck}/>
      case Step.Truck:
        return <TruckSelector truckModel={truckModel} pumpModel={pumpModel} onSelect={onSelect}/>
      case Step.Options:
        return <Options setSelectedOptions={setSelectedOptions} pumpModel={pumpModel}/>
      case Step.Summary:
        return <Summary pumpModel={pumpModel} truckModel={truckModel} hasTruck={hasTruck} onSubmit={onSubmit}/>
      default:
        return <PumpTypeSelector onSelect={onSelect}/>
    }
  }

  return (
    <Layout className={"layout"}>
      <Header className={"header"}>
        <img className={"header-builder"} height={16} alt={"builder"} src={"/image/header/builder.png"}/>
        <img alt={"logo"} src={"/image/header/logo.png"}/>
        <img className={"header-builder"} style={{visibility: "hidden"}} height={16} alt={"builder"}
             src={"/image/header/builder.png"}/>
      </Header>
      <Content className={"content"}>
        <div className={"stepper-menu-wrapper"}>
          <div className={"stepper-menu"}>
            {stepperItems.map((item, index) => renderStep(item.key, item.label, index))}
          </div>
          <div className={"mobile-stepper-menu"}>
            {stepperItems.find((item) => item.key === pathname)?.label || "Pump Type"}
          </div>
        </div>
        <div className={"content-main-wrapper"}>
          {contentSelector()}
        </div>
      </Content>
    </Layout>
  )
}
