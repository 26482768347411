import axios from 'axios';

const API_URL_DEV = 'http://kcpapi.uplink.plus';
const API_URL_PRD = 'https://adm.kcppumps.ca';
const IS_DEV = process.env.REACT_APP_NODE_ENV !== 'production';
const API_URL = IS_DEV ? API_URL_DEV : API_URL_PRD;
console.log("heheheh", IS_DEV);
class API {
  static async request(path: string, reqData: any) {
    try {
      const res = await axios.request({
        url: `${API_URL}/${path}`,
        method: 'POST',
        data: reqData?.data ? reqData : {...reqData, data: {}},
      });
      const {
        error_msg: message,
        data: resData,
        total_count: totalCount = 0,
      } = res.data;

      if (!message) {
        return { data: resData, totalCount };
      } else {
        if (message === 'TOKEN') {
          // await this.getDummyToken();
          return { message };
        }
        // await Router.push(routes.errorPage);
        return { data: null, totalCount: 0, message };
      }
    } catch (error) {
      // @ts-ignore
      if (error?.isAxiosError && error?.response?.status === 401) {
        return null;
      }
      // @ts-ignore
      console.log('Error::', error.message);
      throw error;
    }
  }
}

export default API;
