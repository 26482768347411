import API from './API';
import {ReqGetOptionList, ReqGetPumpModelList, ReqGetTruckList, ReqSetPumpBuilderRequestQuote} from "./types";

const getToken = () => {
  return localStorage.getItem('token');
}
const setToken = (token: string) => {
  localStorage.setItem('token', token);
}

export const getDummyToken = async () => {
  const action = 'GetDummyToken';
  const reqData = {action};
  const res = await API.request('pbuilder/R', reqData);
  setToken(res?.data.token);
}

export const getPumpModelList = async (data: ReqGetPumpModelList) => {
  const action = 'GetPumpModelList';
  const reqData = {action, data};
  return await API.request('pbuilder/R', reqData);
}

export const getTruckList = async (data: ReqGetTruckList) => {
  const action = 'GetTruckList';
  const reqData = {action, data};
  return await API.request('pbuilder/R', reqData);
}

export const getOptionList = async (data: ReqGetOptionList) => {
  const action = 'GetOptionList';
  const reqData = {action, data};
  return await API.request('pbuilder/R', reqData);
}

export const setPumpBuilderRequestQuote = async (data: ReqSetPumpBuilderRequestQuote) => {
  const action = 'SetPumpBuilderRequestQuote';
  const reqData = {action, data};
  return await API.request('pbuilder/R', reqData);
}
